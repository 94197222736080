import { useEffect, useMemo } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router";

import withInitializedData from "./hoc/withInitializedData";
import routes from "routes";
import { toggleNoScroll } from "utils/helpers/view";
import NotFound from "./views/NotFound";
import View from "./views/View";

export default function App() {
  const location = useLocation();

  // Reloads the page if a mobile browser pressed back and is serving from cache
  useEffect(() => {
    window.onpageshow = function (event) {
      const performanceType = window.performance && window.performance.navigation && window.performance.navigation.type ? window.performance.navigation.type : undefined;

      // eslint-disable-next-line
      if (event.persisted || performanceType == 2) {
        window.location.reload();
      }
    };
  }, []);

  // Handle scroll when a new page is loaded
  useEffect(() => {
    toggleNoScroll(false);
    window.scrollTo(0, 0);
  }, [location]);

  // Wrapper component - ensures data is fetched before rendering the main component
  let ViewComponent = useMemo(() => withInitializedData(View), []);

  return (
    <Routes>
      {routes.map(({ key, path, setup, Component }) => {
        return (
          <Route
            key={key}
            path={path}
            element={
              <ViewComponent pageKey={key} {...setup}>
                <Component />
              </ViewComponent>
            }
          />
        );
      })}

      <Route path={"/notfound"} element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/notfound" />} />
    </Routes>
  );
}
