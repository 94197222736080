import { get } from "@crunchit/http-client";
import { IOrderDto, IOrderQueryParams } from "models/order";
import { getToken, getEnvironment, getOrderParamString } from "utils/helpers/service";

const api = process.env.REACT_APP_ORDER_URI || `order.${getEnvironment()}crunchy.ninja/api`;

const OrderService = {
  getOrderById: async (id: string) => {
    return get<IOrderDto>(`${api}/order/${id}`, getToken());
  },
  getOrders: async (params?: IOrderQueryParams) => {
    const paramString = params ? getOrderParamString(params) : "";

    return get<IOrderDto[]>(`${api}/order${paramString}`, getToken());
  },
};

export default OrderService;
