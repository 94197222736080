import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICriticalError } from "@crunchit/types";
import { loadOrders } from "./thunks";
import { IOrderState } from "models/store";
import { chosenDateName } from "utils/constants/storage";

/* Order slice */

const initialState: IOrderState = {
  chosenDate: "",
  orders: [],
  isLoading: false,
};

export const menuSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setChosenDateUtc: (state, action: PayloadAction<string>) => {
      state.chosenDate = action.payload;
      sessionStorage.setItem(chosenDateName, action.payload);
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<ICriticalError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetError: (state) => {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadOrders.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(loadOrders.fulfilled, (state, action) => {
        state.orders = action.payload.orders;
        state.isLoading = false;
      });
  },
});

export const slice = menuSlice.reducer;
export const actions = menuSlice.actions;
