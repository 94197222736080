import useTranslationText from "hooks/useTranslationText";
import { ModifierColumn } from "models/table";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

interface IModifierTableHeaderProps {
  column: ModifierColumn;
}

export default function ModifierTableHeader({ column }: IModifierTableHeaderProps) {
  const { modifierId, translationKey } = column;
  const { modifiers } = useAppSelector();
  const { t } = useTranslation();
  const { getTranslationText } = useTranslationText();

  const modifier = modifiers.find((m) => m.id === modifierId);

  if (translationKey) {
    return <th className={column.className}>{t(translationKey)}</th>;
  }

  if (modifier) {
    return <th className={column.className}>{modifier.internalName || getTranslationText(modifier.name)}</th>;
  }

  return <th className={column.className}></th>;
}
