import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TimeInput from "./TimeInput";
import "./TimeFilterMenu.scss";

interface ITimeFilterProps {
  fromTime: Date | null;
  toTime: Date | null;
  handleFiltering: (fromTime: Date, toTime: Date) => void;
  handleReset: () => void;
}

export default function TimeFilterMenu(props: ITimeFilterProps) {
  const { fromTime, toTime, handleFiltering, handleReset } = props;
  const { t } = useTranslation();

  const [menuFromTime, setMenuFromTime] = useState<Date | null>(fromTime);
  const [menuToTime, setMenuToTime] = useState<Date | null>(toTime);

  const invalidInput = useMemo(() => menuFromTime === null || menuToTime === null, [menuFromTime, menuToTime]);

  function handleFromTimeUpdated(newTime: Date | null) {
    setMenuFromTime(newTime ? new Date(newTime.getTime()) : newTime);
  }

  function handleToTimeUpdated(newTime: Date | null) {
    setMenuToTime(newTime ? new Date(newTime.getTime()) : newTime);
  }

  const handleFilterClick = useCallback(() => {
    handleFiltering(menuFromTime as Date, menuToTime as Date);
  }, [menuFromTime, menuToTime, handleFiltering]);

  return (
    <div className="menu">
      <div className="time">
        <div className="time-input-group">
          <label>{t("table:TimeFilter.From")}:</label>
          <TimeInput time={menuFromTime} handleTimeUpdate={handleFromTimeUpdated} />
        </div>

        <div className="time-input-group">
          <label>{t("table:TimeFilter.To")}:</label>
          <TimeInput time={menuToTime} handleTimeUpdate={handleToTimeUpdated} />
        </div>
      </div>

      <div className="action">
        <button className="button" onClick={handleFilterClick} disabled={invalidInput}>
          {t("table:TimeFilter.Filter")}
        </button>

        <button className="button" onClick={handleReset}>
          {t("table:TimeFilter.Reset")}
        </button>
      </div>
    </div>
  );
}
