import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickAway from "hooks/useClickAway";
import TimeFilterMenu from "./menu/TimeFilterMenu";
import "./TimeFilter.scss";

interface ITimeFilterProps {
  handleFiltering: (fromTime: Date, toTime: Date) => void;
  handleReset: () => void;
}

export default function TimeFilter(props: ITimeFilterProps) {
  const { handleFiltering, handleReset } = props;
  const { t } = useTranslation();

  const menuClassName = "menu";

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [fromTime, setFromTime] = useState<Date | null>(null);
  const [toTime, setToTime] = useState<Date | null>(null);

  const openMenu = () => setMenuIsOpen(true);
  const closeMenu = () => setMenuIsOpen(false);

  useClickAway(menuClassName, closeMenu);

  const triggerText = useMemo(() => {
    if (fromTime && toTime) {
      return `${moment(fromTime).format("HH:mm")} - ${moment(toTime).format("HH:mm")}`;
    }

    return t("table:TimeFilter.Placeholder");
  }, [fromTime, toTime, t]);

  const onFiltering = (fromTime: Date, toTime: Date) => {
    setFromTime(fromTime);
    setToTime(toTime);
    setMenuIsOpen(false);

    handleFiltering(fromTime, toTime);
  };

  const onReset = useCallback(() => {
    setFromTime(null);
    setToTime(null);
    setMenuIsOpen(false);

    handleReset();
  }, [handleReset]);

  return (
    <div className="time-filter">
      <button className="trigger button" onClick={openMenu}>
        {triggerText}
      </button>

      {menuIsOpen && <TimeFilterMenu fromTime={fromTime} toTime={toTime} handleFiltering={onFiltering} handleReset={onReset} />}
    </div>
  );
}
