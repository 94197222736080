import { IProductRow } from "models/table";

export default function useTableFiltering() {
  const filterByDate = (data: readonly IProductRow[], fromTime: Date, toTime: Date) => {
    const fromTimeTimestamp = fromTime.getTime();
    const toTimeTimestamp = toTime.getTime();

    const filteredData = data.filter(({ orderReadyTime }) => {
      const orderReadyTimeTimestamp = orderReadyTime.getTime();
      return orderReadyTimeTimestamp >= fromTimeTimestamp && orderReadyTimeTimestamp <= toTimeTimestamp;
    });

    return filteredData;
  };

  const filterByReceiptId = (data: readonly IProductRow[], externalId: string) => {
    const filteredData = data.filter((data) => {
      return data.externalId.toString().includes(externalId);
    });

    return filteredData;
  };

  return {
    filterByDate,
    filterByReceiptId,
  };
}
