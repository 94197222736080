import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import Header from "components/header/Header";
import Spinner from "components/ui/Spinner";
import useAppState from "hooks/useAppState";
import useAppRedirects from "hooks/useAppRedirects";
import { IViewProps } from "models/layout";
import { useAppSelector } from "store/app";

// Performing all page checks necessary before loading content
export default function View(props: IViewProps) {
  const { pageKey, layoutOptions, children } = props;
  const { isStatusPage = false } = layoutOptions || {};

  const { appIsAuthenticated } = useAppSelector();
  const { isError, isLoading } = useAppState();

  const redirect = useNavigate();
  const redirectPath = useAppRedirects({ isError, isLoading });

  const showHeader = useMemo(() => appIsAuthenticated, [appIsAuthenticated]);

  useEffect(() => {
    if (redirectPath) {
      redirect(redirectPath);
    }
  }, [redirectPath, redirect]);

  return (
    <div className={`page-view ${isStatusPage ? "status-page" : ""}`}>
      {showHeader && <Header />}

      <main className={`${pageKey}-page`}>{children}</main>

      {isLoading && <Spinner />}
    </div>
  );
}
