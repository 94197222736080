import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/forms";
import "./Search.scss";

interface ISearchProps {
  handleSearch: (value: string) => void;
}

export default function Search(props: ISearchProps) {
  const { handleSearch } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  function handleInputChanged(value: string) {
    setValue(value);
    handleSearch(value);
  }

  return (
    <div className="search">
      <Input inputValue={value} inputChanged={({ value }) => handleInputChanged(value)} overrideParameters={{ name: "search", type: "text", placeholder: t("table:Search.Placeholder") }} />
    </div>
  );
}
