import { IOrderItemChoiceDto } from "@crunchit/types";
import useTranslationText from "hooks/useTranslationText";
import { useAppSelector } from "store/app";

interface IChoiceProps {
  choice: IOrderItemChoiceDto;
}

export default function Choice({ choice }: IChoiceProps) {
  const { modifiers } = useAppSelector();
  const { getTranslationText } = useTranslationText();

  const modifier = modifiers.find((m) => m.id === choice.modifierId);

  return (
    <>
      <p className="label">{modifier ? getTranslationText(modifier.name) : choice.modifierName}:</p>
      <p>{choice.name}</p>
    </>
  );
}
