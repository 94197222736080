import { useTranslation } from "react-i18next";
import { IProductionUnitDto } from "@crunchit/types";

import PageTitle from "./PageTitle";
import RestaurantInfo from "./RestaurantInfo";

import "./ErrorMessage.scss";

interface IErrorMessageProps {
  productionUnit?: IProductionUnitDto | null;
  isErrorBoundary?: boolean;
  isBrowserError?: boolean;
  handleButtonClick: () => void;
}

// Error message view for generic error page + error boundary
export default function ErrorMessage(props: IErrorMessageProps) {
  const { productionUnit, isErrorBoundary, isBrowserError, handleButtonClick } = props;
  const { t } = useTranslation();

  let operationId;

  if (window.appInsights && window.appInsights.context && window.appInsights.context.telemetryTrace) {
    operationId = window.appInsights.context.telemetryTrace.traceID;
  }

  return (
    <>
      <PageTitle title={t("pagetitles:ErrorPage.Title")} subtitle={operationId ? operationId : undefined} />

      <div className="content-body error-message-content">
        <p className="status-page-text error-message-text">{isBrowserError ? t("errors:ErrorMessage.UnsupportedBrowser") : t("errors:ErrorMessage.TryAgain")}</p>

        {!isBrowserError && !isErrorBoundary && productionUnit && <RestaurantInfo productionUnit={productionUnit} />}

        <button className="button" onClick={handleButtonClick}>
          {isErrorBoundary ? t("errors:Actions.TryAgain") : t("errors:Actions.BackToFrontpage")}
        </button>
      </div>
    </>
  );
}
