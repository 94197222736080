import da from "date-fns/locale/da";
import moment from "moment";
import { useMemo, useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker"; // https://reactdatepicker.com/
import { useTranslation } from "react-i18next";

import { useOrderSelector } from "store/order";
import "./CustomDatepicker.scss";

interface IDatepickerProps {
  chosenCustomDate: moment.Moment | null;
  isActive: boolean;
  handleDateChange: (date: moment.Moment) => void;
}

export default function Datepicker(props: IDatepickerProps) {
  const { chosenCustomDate, isActive, handleDateChange } = props;

  const { isLoading } = useOrderSelector();
  const { t } = useTranslation();

  const formattedCustomDate = useMemo(() => (chosenCustomDate ? moment(chosenCustomDate).format("DD-MM-YYYY") : ""), [chosenCustomDate]);
  const dateAsJSDate = useMemo(() => (chosenCustomDate ? moment(chosenCustomDate, "DD-MM-YYYY").toDate() : undefined), [chosenCustomDate]);
  const placeholderText = useMemo(() => (formattedCustomDate ? formattedCustomDate : t("date:Datepicker")), [formattedCustomDate, t]);

  // // Registering a locale to handle language in the datepicker
  registerLocale("da", da);

  // // Hack to disable keyboard in the datepicker on mobile devices
  const onDatepickerRef = useCallback((el: any) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }, []);

  return (
    <div className={`custom-datepicker-container ${isActive ? "active" : ""}`}>
      <DatePicker id="custom-datepicker" dateFormat="d. MMMM" locale="da" selected={dateAsJSDate} disabled={isLoading} highlightDates={dateAsJSDate ? [dateAsJSDate] : []} placeholderText={placeholderText} previousMonthButtonLabel={t("date:PreviousMonth")} nextMonthButtonLabel={t("date:NextMonth")} calendarClassName={isLoading ? "hide-calendar" : ""} ref={(el) => onDatepickerRef(el)} onChange={(date) => handleDateChange(moment(date))} />
    </div>
  );
}
