import { useCallback, useState } from "react";

import useTableSorting from "../product/useTableSorting";
import { IOrderProductDto } from "models/order";
import { IOrderProductRow, ProductAmountMap, SortingFunction } from "models/table";
import { useAppSelector } from "store/app";
import { orderProductSortingFunctions } from "utils/constants/table";

/**
 * Prepares order data.
 *
 * `groupName` filters order products by their group tag, e.g. "brød".
 * If no `groupName` is provided, all order products are included.
 */
export default function useProductionUnitsTable() {
  const { productionUnits } = useAppSelector();
  const { sort, sortIcon, setSortingFunction } = useTableSorting<IOrderProductRow>();

  const [rows, setRows] = useState<IOrderProductRow[]>([]);

  /**
   * Returns a new lookup table (map) for product amounts per production unit
   */
  function getProductionUnitProductAmountMap(orderProducts: IOrderProductDto[]) {
    const productAmountMap: ProductAmountMap = new Map();

    orderProducts.forEach((orderProduct) => {
      const productIdMap = productAmountMap.get(orderProduct.productionUnitId) || new Map<number, number>();
      const productAmount = productIdMap.get(orderProduct.productId) || 0;

      productIdMap.set(orderProduct.productId, productAmount + orderProduct.amount);
      productAmountMap.set(orderProduct.productionUnitId, productIdMap);
    });

    return productAmountMap;
  }

  function getUniqueProducts(orderProducts: IOrderProductDto[]) {
    return orderProducts.filter(({ productId }, i, array) => array.findIndex((p) => p.productId === productId) === i);
  }

  const mapProductionUnitAmounts = useCallback(
    (orderProduct: IOrderProductDto, productionUnitProductAmountMap: ProductAmountMap): IOrderProductRow => {
      const totalAmount = productionUnits.reduce((total, productionUnit) => {
        const productionUnitProducts = productionUnitProductAmountMap.get(productionUnit.id);
        const productionUnitAmountOfOrderProduct = productionUnitProducts ? productionUnitProducts.get(orderProduct.productId) : 0;

        return total + (productionUnitAmountOfOrderProduct || 0);
      }, 0);

      return {
        ...orderProduct,
        totalAmount,
        amountPerProductionUnit: productionUnitProductAmountMap,
      };
    },
    [productionUnits]
  );

  const handleSorting = useCallback(
    (sortingFunction: SortingFunction<IOrderProductRow>) => {
      const sortedRows = sort(rows, sortingFunction);
      setRows([...sortedRows]);
    },
    [sort, rows]
  );

  /**
   * Readies data to be viewed in the table.
   */
  const loadRows = useCallback(
    (orderProducts: IOrderProductDto[]) => {
      const defaultSortingFunction = orderProductSortingFunctions.sortByName;
      setSortingFunction(defaultSortingFunction);

      const productionUnitProductAmountMap = getProductionUnitProductAmountMap(orderProducts);

      const rows = getUniqueProducts(orderProducts)
        .map<IOrderProductRow>((o) => mapProductionUnitAmounts(o, productionUnitProductAmountMap))
        .sort(defaultSortingFunction);

      setRows(rows);
    },
    [mapProductionUnitAmounts]
  );

  return {
    loadRows,
    rows,
    handleSorting,
    sortIcon,
  };
}
