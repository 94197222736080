import { useCallback, useMemo, useState } from "react";
import { useOrderSelector } from "store/order";
import "./TimeInput.scss";

interface ITimeInputProps {
  time: Date | null;
  handleTimeUpdate: (time: Date | null) => void;
}

export default function TimeInput(props: ITimeInputProps) {
  const { time, handleTimeUpdate } = props;
  const { chosenDate } = useOrderSelector();

  const [inputTime, setInputTime] = useState("");

  function onTimeInputChange(event: React.ChangeEvent) {
    const target = event.target as HTMLInputElement;
    setInputTime(target.value);
  }

  const submitChanges = useCallback(() => {
    const [hours, minutes] = inputTime.split(":");

    if (!hours || !minutes) {
      return;
    }

    try {
      const hoursInt = parseInt(hours);
      const minutesInt = parseInt(minutes);

      let newTime;

      if (time) {
        newTime = time;
      } else {
        newTime = new Date(chosenDate);
        newTime.setHours(0);
        newTime.setSeconds(0);
        newTime.setMilliseconds(0);
      }

      newTime.setHours(hoursInt);
      newTime.setMinutes(minutesInt);

      handleTimeUpdate(newTime);
    } catch (error) {
      return;
    }
  }, [inputTime, time, chosenDate, handleTimeUpdate]);

  const defaultValue = useMemo(() => (time ? `${time.getHours()}:${time.getMinutes()}` : ""), [time]);

  return <input className="time-input" type="time" defaultValue={defaultValue} onChange={onTimeInputChange} onBlur={submitChanges} />;
}
