import { Link } from "react-router-dom";

interface IListItemProps {
  listKey: string;
  orderProductCount: number | null;
}

export default function ListItem(props: IListItemProps) {
  const { listKey, orderProductCount } = props;

  return (
    <li className="list-item">
      <Link className="list-item-content like-button" to={{ pathname: `/list/${encodeURI(listKey)}` }}>
        <span>{listKey}</span>
        {orderProductCount !== null && <span className="count">{orderProductCount}</span>}
      </Link>
    </li>
  );
}
