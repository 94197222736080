import { IProductionUnitDto } from "@crunchit/types";
import { productionUnitColors } from "utils/constants/colors";

interface IProductionUnitTableHeaderProps {
  productionUnit: IProductionUnitDto;
}

export default function ProductionUnitTableHeader({ productionUnit }: IProductionUnitTableHeaderProps) {
  const productionUnitColor = productionUnitColors[productionUnit.id] || "none";

  return (
    <th className={`production-unit bg-${productionUnitColor}`} key={productionUnit.id}>
      {productionUnit.name}
    </th>
  );
}
