import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Choice from "./Choice";
import PrintButton from "components/print/PrintButton";
import { IOrderDetails } from "models/table";

import "./OrderDetails.scss";

interface IOrderDetailsProps {
  orderDetails: IOrderDetails;
  handleDismiss: () => void;
}

export default function OrderDetails(props: IOrderDetailsProps) {
  const { orderDetails, handleDismiss } = props;
  const { order, product } = orderDetails;
  const { t } = useTranslation();

  const isDelivery = useMemo(() => order.delivery !== undefined, [order.delivery]);
  const orderReadyTimeString = useMemo(() => `${moment(product.orderReadyTime).format("DD-MM-YYYY")} kl. ${moment(product.orderReadyTime).format("HH:mm")}`, [product.orderReadyTime]);
  const choices = useMemo(() => product.choices || [], [product]);

  return (
    <div className="order-details">
      <header>
        <div className="title">
          <h1>
            {t("order:OrderDetails.Title")} <span>{order.receipt.number}</span>
          </h1>

          <h2>{order.externalId}</h2>
        </div>

        <div className="print">
          <PrintButton />

          <button className="exit-button" onClick={handleDismiss}>
            <i className="icon exit-icon"></i>
          </button>
        </div>
      </header>

      <section>
        <div className="row">
          <p className="label">{t("order:OrderDetails.Product")}:</p>
          <p>
            ({product.amount}) {product.productName}
          </p>
        </div>

        {choices.map((choice) => (
          <div className="row" key={`${choice.modifierId}-${choice.choiceId}`}>
            <Choice choice={choice} />
          </div>
        ))}

        <div className="row">
          <p className="label">{t("order:OrderDetails.Text")}:</p>
          <p>{product.comment}</p>
        </div>
      </section>

      <section>
        <div className="row">
          <p className="label">{t("order:OrderDetails.Location")}:</p>
          <p>{product.location?.name}</p>
        </div>

        <div className="row">
          <p className="label">{t(`order:OrderDetails.ReadyTime.${isDelivery ? "Delivery" : "Pickup"}`)}:</p>
          <p>{orderReadyTimeString}</p>
        </div>

        <div className="row">
          <p className="label">{t("order:OrderDetails.Customer.Name")}:</p>
          <p>{order.customer.name}</p>
        </div>

        <div className="row">
          <p className="label">{t("order:OrderDetails.Customer.Phone")}:</p>
          <p>{order.customer.phone}</p>
        </div>
      </section>

      <section>
        <div className="row">
          <p className="label">{t("order:OrderDetails.Comment")}:</p>
          <p>{order.comment}</p>
        </div>
      </section>
    </div>
  );
}
