import { ReactNode } from "react";
import useClickAway from "hooks/useClickAway";
import "./Modal.scss";

interface IModalProps {
  content: ReactNode;
  handleDismiss: () => void;
}

export default function Modal(props: IModalProps) {
  const { content, handleDismiss } = props;
  const modalClassName = "modal";

  useClickAway(modalClassName, handleDismiss);

  return (
    <aside className="modal-wrapper">
      <div className={modalClassName}>{content}</div>
    </aside>
  );
}
