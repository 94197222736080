import { ICriticalError, IModifierDto, IModuleDto, IProductionUnitDto } from "@crunchit/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ModuleService from "services/ModuleService";
import ProductService from "services/ProductService";
import ProductionUnitService from "services/ProductionUnitService";

type Rejection = { rejectValue: ICriticalError };
type InitializeSuccess = { productionUnits: IProductionUnitDto[]; modules: IModuleDto[] };
type LoadModifiersSuccess = { modifiers: IModifierDto[] };

/* Async reducers */

export const initialize = createAsyncThunk<InitializeSuccess, void, Rejection>("app/initialize", async (data, { rejectWithValue }) => {
  try {
    const [productionUnitsResponse, modulesResponse] = await Promise.all([ProductionUnitService.getProductionUnits(), ModuleService.getModules()]);

    if (!productionUnitsResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to fetch production units`, error: productionUnitsResponse.errors });
    }

    if (!modulesResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to fetch modules'`, error: modulesResponse.errors });
    }

    const modules = modulesResponse.data;

    return { productionUnits: productionUnitsResponse.data, modules };
  } catch (error) {
    return rejectWithValue({ message: "Unable to initialize app", error: error instanceof Error ? error.message : error });
  }
});

export const loadModifiers = createAsyncThunk<LoadModifiersSuccess, IModuleDto[], Rejection>("app/loadModifiers", async (modules, { rejectWithValue }) => {
  try {
    const productInstanceIds = modules
      .map((module) => module.productInstanceId)
      .filter((productInstanceId) => productInstanceId !== null)
      .filter((productInstanceId, i, array) => array.findIndex((id) => id === productInstanceId) === i);

    const modifierResponses = await Promise.all(productInstanceIds.map((productInstanceId) => ProductService.getModifiers(productInstanceId)));

    const modifiers = modifierResponses
      .map((response) => (response.isSuccess() ? response.data : null))
      .filter((modifiers) => modifiers !== null)
      .flat() as IModifierDto[];

    return { modifiers };
  } catch (error) {
    return { modifiers: [] };
  }
});

const thunks = {
  initialize,
  loadModifiers,
};

export default thunks;
