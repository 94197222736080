import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { useAppSelector } from "store/app";

interface IRedirectHookProps {
  isError: boolean;
  isLoading: boolean;
}

export default function useAppRedirects(props: IRedirectHookProps) {
  const { isError, isLoading } = props;

  const { appIsAuthenticated } = useAppSelector();
  const { pathname } = useLocation();

  let [redirectPath, setRedirectPath] = useState("");

  // Redirects based on global redux states

  useEffect(() => {
    if (!isLoading && !appIsAuthenticated && !pathname.includes("login")) {
      setRedirectPath("/login");
    }
  }, [isLoading, appIsAuthenticated, pathname]);

  useEffect(() => {
    if (appIsAuthenticated && pathname.includes("login")) {
      setRedirectPath("/");
    }
  }, [appIsAuthenticated, pathname]);

  useEffect(() => {
    if (isError && pathname.indexOf("error") === -1) {
      // Global state dictates when we show an error page
      setRedirectPath("/error");
    }
  }, [isError, pathname]);

  // Everytime the path changes, we reset the redirect
  useEffect(() => {
    setRedirectPath("");
  }, [pathname]);

  return redirectPath;
}
