import "./PageTitle.scss";

interface IPageTitleProps {
  title: string;
  subtitle?: string;
}

export default function PageTitle(props: IPageTitleProps) {
  const { title, subtitle } = props;

  return (
    <div className="content-title-container page-title-container">
      <h1 className="content-title page-title">{title}</h1>

      {subtitle !== undefined && <p className="content-subtitle page-subtitle">{subtitle}</p>}
    </div>
  );
}
