import ErrorPage from "views/ErrorPage";
import Home from "views/Home";
import Maintenance from "views/Maintenance";
import List from "views/List";
import Login from "views/Login";

const routes = [
  {
    path: "/",
    key: "root",
    Component: Home,
    setup: {
      dataOptions: { useBaskets: true, useCategories: true },
    },
  },
  {
    path: "/login",
    key: "login",
    Component: Login,
    setup: {},
  },
  {
    path: "/list/:name",
    key: "list",
    Component: List,
    setup: {},
  },
  {
    path: "/maintenance",
    key: "maintenance",
    Component: Maintenance,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true },
    },
  },
  {
    path: "/error",
    key: "error",
    Component: ErrorPage,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true },
    },
  },
];

export default routes;
