import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import TimeFilter from "./filter/TimeFilter";
import ModifierTableHeader from "./header/ModifierTableHeader";
import SortableTableHeader from "./header/SortableTableHeader";
import ProductTableRow from "./row/ProductTableRow";
import Search from "./search/Search";
import useProductTable from "./useProductTable";
import useAppState from "hooks/useAppState";
import useOrderProducts from "hooks/useOrderProducts";
import { IOrderDetails } from "models/table";
import { useAppSelector } from "store/app";
import { List } from "utils/constants/list";
import { productTableColumnConfig } from "utils/constants/table";

import "./ProductTable.scss";

interface IProductTable {
  list: string;
  handleInspectOrder: (order: IOrderDetails) => void;
}

/**
 * Product overview.
 *
 * Displays sortable and searchable details about products in orders.
 */
export default function ProductTable(props: IProductTable) {
  const { list, handleInspectOrder } = props;
  const { appIsInitialized } = useAppSelector();
  const { isLoading } = useAppState();
  const { t } = useTranslation();
  const { getOrderProducts } = useOrderProducts();

  const { loadRows, rows, handleSorting, handleDateFiltering, handleSearch, handleResetFiltering, sortIcon } = useProductTable();

  const [rowsLoaded, setRowsLoaded] = useState(false);

  // Load columns configured for the group tag
  const columns = useMemo(() => productTableColumnConfig[list as List], [list]);
  const isEmpty = useMemo(() => rows.length === 0, [rows]);

  useEffect(() => {
    if (appIsInitialized && !isLoading && !rowsLoaded) {
      const orderProducts = getOrderProducts(list);

      loadRows(orderProducts);
      setRowsLoaded(true);
    }
  }, [appIsInitialized, isLoading, rowsLoaded, getOrderProducts, list, loadRows]);

  useEffect(() => {
    setRowsLoaded(false);
  }, [getOrderProducts]);

  return (
    <>
      <div className="table-actions">
        <Search handleSearch={handleSearch} />
        <TimeFilter handleFiltering={handleDateFiltering} handleReset={handleResetFiltering} />
      </div>

      <div className="table-wrapper">
        <table className="product-table">
          <thead>
            <tr>
              {columns.map((column, i) => {
                if (column.isModifierColumn()) {
                  return <ModifierTableHeader column={column} key={i} />;
                }

                if (column.sorting) {
                  return <SortableTableHeader text={t(column.translationKey)} sortingFunction={column.sorting} classNames={column.className} getIcon={sortIcon} handleClick={handleSorting} key={i} />;
                }

                return (
                  <th className={column.className} key={i}>
                    {t(column.translationKey)}
                  </th>
                );
              })}

              {!isEmpty && <th className="more"></th>}
            </tr>
          </thead>

          <tbody>
            {isEmpty ? (
              <tr>
                <td colSpan={columns.length}>{t("table:Empty")}</td>
              </tr>
            ) : (
              rows.map((product, i) => <ProductTableRow product={product} columns={columns} handleInspectOrder={handleInspectOrder} key={i} />)
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
