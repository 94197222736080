import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logoSrc from "assets/img/logo.svg";
import useAuth from "hooks/useAuth";
import "./Header.scss";
import PrintButton from "components/print/PrintButton";

export default function Header() {
  const { t } = useTranslation();
  const { signOut } = useAuth();

  return (
    <header id="page-header">
      <nav>
        <Link className="logo-link" to={{ pathname: "/" }}>
          <img className="logo" src={logoSrc} alt="" />
        </Link>

        <div className="actions">
          <PrintButton />

          <button className="button logout-button" onClick={signOut}>
            {t("login:LogOut")}
          </button>
        </div>
      </nav>
    </header>
  );
}
