import common from "./common.json";
import date from "./date.json";
import errors from "./errors.json";
import order from "./order.json";
import login from "./login.json";
import pagetitles from "./pagetitles.json";
import table from "./table.json";

export const en = {
  common,
  date,
  errors,
  login,
  order,
  pagetitles,
  table,
};
