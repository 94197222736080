import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICriticalError } from "@crunchit/types";
import { IAppState } from "models/store";
import { initialize, loadModifiers } from "./thunks";

/* Global app slice */

const initialState: IAppState = {
  token: "",
  productionUnits: [],
  modules: [],
  modifiers: [],
  languages: [],
  isLoading: true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<ICriticalError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    reset: (state) => {
      state.token = "";
      state.productionUnits = [];
      state.modules = [];
      state.modifiers = [];
      state.languages = [];
    },
    resetError: (state) => {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialize.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(initialize.fulfilled, (state, action) => {
        state.productionUnits = action.payload.productionUnits;
        state.modules = action.payload.modules;
        state.isLoading = false;
      })

      .addCase(loadModifiers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadModifiers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(loadModifiers.fulfilled, (state, action) => {
        state.modifiers = action.payload.modifiers;
        state.isLoading = false;
      });
  },
});

export const slice = appSlice.reducer;
export const actions = appSlice.actions;
