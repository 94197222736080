import { useTranslation } from "react-i18next";
import { Column, IOrderDetails, IProductRow } from "models/table";
import { choiceColors } from "utils/constants/colors";
import { IOrderItemChoiceDto } from "@crunchit/types";

interface IProductTableRowProps {
  product: IProductRow;
  columns: Column[];
  handleInspectOrder: (order: IOrderDetails) => void;
}

export default function ProductTableRow({ product, columns, handleInspectOrder }: IProductTableRowProps) {
  const { t } = useTranslation();

  const ChoiceLabel = (modifierId: number, choice: IOrderItemChoiceDto) => {
    const colors = choiceColors[modifierId];
    const backgroundColor = colors && colors[choice.choiceId];
    const className = `bg-${backgroundColor || "none"}`;

    return <span className={className}>{choice.name}</span>;
  };

  const ChoiceLabels = (modifierId: number) => {
    const choices = product.choices?.filter((c) => c.modifierId === modifierId);
    return <div className="choice-labels">{choices?.map((c) => ChoiceLabel(modifierId, c))}</div>;
  };

  const onMoreButtonClick = () => {
    handleInspectOrder({ order: product.order, product });
  };

  return (
    <tr>
      {columns.map((column, i) => (
        <td key={i}>{column.isModifierColumn() ? ChoiceLabels(column.modifierId) : column.content(product)}</td>
      ))}

      <td className="more">
        <button className="button" onClick={onMoreButtonClick}>
          {t("table:ProductTable.More")}
        </button>
      </td>
    </tr>
  );
}
