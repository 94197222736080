import { get } from "@crunchit/http-client";
import { IModifierDto } from "@crunchit/types";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_PRODUCT_URI || `product.${getEnvironment()}crunchy.ninja/api`;

const ProductService = {
  getModifiers: async (productInstanceId: string) => {
    return get<IModifierDto[]>(`${api}/modifier?instanceId=${productInstanceId}`, getToken());
  },
};

export default ProductService;
