import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import ErrorMessage from "components/ui/ErrorMessage";
import { useCustomDispatch } from "store/useStore";
import { useAppSelector, appActions } from "store/app";
import { orderActions } from "store/order";
import { browserIsOutdated, logICriticalError, logError } from "utils/helpers/errors";

export default function ErrorPage() {
  const { error } = useAppSelector();
  const dispatch = useCustomDispatch();
  const redirect = useNavigate();

  // Checking if the user is in Internet Explorer
  const isBrowserError = useMemo(() => browserIsOutdated(), []);

  useEffect(() => {
    if (error) {
      logICriticalError(error);
    }
  }, [error]);

  useEffect(() => {
    try {
      dispatch(appActions.resetError());
      dispatch(orderActions.resetError());
    } catch (error) {
      logError(error);
    }
  }, [dispatch]);

  return <ErrorMessage isBrowserError={isBrowserError} handleButtonClick={() => redirect("/")} />;
}
