import { useCustomSelector } from "store/useStore";

// Computing values from redux state
export default function useSelector() {
  const state = useCustomSelector((state) => state.app);

  const { token, isLoading, productionUnits } = state;

  // Computed value indicating app is initialized and content can load
  const appIsAuthenticated = token ? true : false;
  const appIsInitialized = !isLoading && productionUnits.length > 0;

  return {
    ...state,
    appIsAuthenticated,
    appIsInitialized,
  };
}
