import { useCallback, useState } from "react";
import { SortingFunction } from "models/table";

export default function useTableSorting<T>() {
  const [dataIsReversed, setDataIsReversed] = useState(false);
  const [currentSortingFunction, setCurrentSortingFunction] = useState<SortingFunction<T> | null>(null);

  const sortIcon = useCallback(
    (sortingFunction: SortingFunction<T>) => {
      if (sortingFunction.name === currentSortingFunction?.name) {
        return dataIsReversed ? "▲" : "▼";
      }

      return "";
    },
    [currentSortingFunction, dataIsReversed]
  );

  const sort = useCallback(
    (data: T[], sortingFunction: SortingFunction<T>) => {
      if (sortingFunction.name === currentSortingFunction?.name) {
        setDataIsReversed(!dataIsReversed);
        return data.reverse();
      }

      setDataIsReversed(false);
      setCurrentSortingFunction(() => sortingFunction);
      return data.sort(sortingFunction);
    },
    [currentSortingFunction, dataIsReversed]
  );

  const applyCurrentSorting = useCallback(
    (data: T[]) => {
      let sortedData = data;

      if (currentSortingFunction) {
        sortedData = data.sort(currentSortingFunction);
      }

      if (dataIsReversed) {
        sortedData.reverse();
      }

      return sortedData;
    },
    [currentSortingFunction, dataIsReversed]
  );

  function setSortingFunction(f: SortingFunction<T> | null) {
    setCurrentSortingFunction(() => f);
  }

  return {
    setSortingFunction,
    sort,
    applyCurrentSorting,
    sortIcon,
  };
}
