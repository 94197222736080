import { List } from "./list";
import TimeTableCell from "components/table/product/cells/TimeTableCell";
import LocationTableCell from "components/table/product/cells/LocationTableCell";
import AmountTableCell from "components/table/product/cells/AmountTableCell";
import ProductNameTableCell from "components/table/product/cells/ProductNameTableCell";
import { IProductRow, Column, ProductColumn, ModifierColumn, IOrderProductRow } from "models/table";

/**
 * Sorting functions for product row
 */
export const productSortingFunctions = {
  sortByTime: (rowA: IProductRow, rowB: IProductRow) => rowA.orderReadyTime.getTime() - rowB.orderReadyTime.getTime(),
  sortByAmount: (rowA: IProductRow, rowB: IProductRow) => rowA.amount - rowB.amount,
  sortByName: (rowA: IProductRow, rowB: IProductRow) => (rowA.productName.toUpperCase() < rowB.productName.toUpperCase() ? -1 : 1),
  sortByLocation: (rowA: IProductRow, rowB: IProductRow) => (rowA.location && rowB.location ? (rowA.location.name.toUpperCase() < rowB.location.name.toUpperCase() ? -1 : 1) : 0),
  sortByReceiptNumber: (rowA: IProductRow, rowB: IProductRow) => (rowA.receiptId < rowB.receiptId ? -1 : 1),
};

/**
 * Sorting functions for order product rows
 */
export const orderProductSortingFunctions = {
  sortByTotalAmount: (rowA: IOrderProductRow, rowB: IOrderProductRow) => rowA.totalAmount - rowB.totalAmount,
  sortByName: (rowA: IOrderProductRow, rowB: IOrderProductRow) => (rowA.name.toUpperCase() < rowB.name.toUpperCase() ? -1 : 1),
};

/**
 * Product data columns for the product table
 */
const idColumn = new ProductColumn({
  translationKey: "table:ProductTable.ReceiptId",
  content: ({ order }) => (order.externalId ? order.externalId : ""),
  sorting: productSortingFunctions.sortByReceiptNumber,
  className: "id",
});

const timeColumn = new ProductColumn({
  translationKey: "table:ProductTable.Time",
  content: TimeTableCell,
  sorting: productSortingFunctions.sortByTime,
  className: "time",
});

const locationColumn = new ProductColumn({
  translationKey: "table:ProductTable.Location",
  content: LocationTableCell,
  sorting: productSortingFunctions.sortByLocation,
  className: "location",
});

const productNameColumn = new ProductColumn({
  translationKey: "table:ProductTable.ProductName",
  content: ProductNameTableCell,
  sorting: productSortingFunctions.sortByName,
  className: "name",
});

const amountColumn = new ProductColumn({
  translationKey: "table:ProductTable.Amount",
  content: AmountTableCell,
  sorting: productSortingFunctions.sortByAmount,
  className: "amount",
});

const productTextColumn = new ProductColumn({
  translationKey: "table:ProductTable.Text",
  content: ({ comment }) => comment || "",
  className: "text",
});

/**
 * Modifier data columns for the product table
 */
const hairColumn = new ModifierColumn(989, "hair", "table:ProductTable.Column.Hair");
const candyColumn = new ModifierColumn(990, "candy", "table:ProductTable.Column.Candy");
const flagColumn = new ModifierColumn(991, "flag", "table:ProductTable.Column.Flag");
const doughColumn = new ModifierColumn(1025, "dough");
const genderColumn = new ModifierColumn(976, "gender", "table:ProductTable.Column.Gender");
const genderTwinColumn = new ModifierColumn(1113, "gender-twin", "table:ProductTable.Column.GenderTwin");
const numberOfPeopleLetter = new ModifierColumn(1036, "people");
const contentOfLettersColumn = new ModifierColumn(1133);

/**
 * Columns and their order per group tag
 */
const defaultColumns: Column[] = [idColumn, timeColumn, locationColumn, productNameColumn, amountColumn];

export const productTableColumnConfig: Record<List, Column[]> = {
  kager: [],
  lagkager: [idColumn, timeColumn, locationColumn, amountColumn, productNameColumn, productTextColumn, flagColumn],
  specialkager: [...defaultColumns, numberOfPeopleLetter, contentOfLettersColumn, productTextColumn, flagColumn],
  specialkagemænd: [...defaultColumns, doughColumn, hairColumn, productTextColumn, flagColumn],
  brød: [],
  wienerbrød: [],
  kagemænd: [...defaultColumns, genderColumn, genderTwinColumn, hairColumn, productTextColumn, candyColumn, flagColumn],
  "interne ordrer": [],
};
