import { IOrderProductRow } from "models/table";
import { useAppSelector } from "store/app";

interface IOrderProductTableRowProps {
  orderProduct: IOrderProductRow;
}

export default function OrderProductTableRow({ orderProduct }: IOrderProductTableRowProps) {
  const { productionUnits } = useAppSelector();

  return (
    <tr key={orderProduct.productId}>
      <td className="name">{orderProduct.name}</td>
      <td className="total-amount">{orderProduct.totalAmount}</td>

      {productionUnits.map((productionUnit) => {
        const amount = orderProduct.amountPerProductionUnit?.get(productionUnit.id)?.get(orderProduct.productId) || 0;
        return <td key={`${orderProduct.productId}/${productionUnit.id}`}>{amount}</td>;
      })}
    </tr>
  );
}
