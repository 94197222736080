type ProductionUnitColors = { [productionUnitId: number]: Color };
type ProductColors = { [productId: number]: Color };
type ChoiceColors = { [modifierId: number]: { [choiceId: number]: Color } };

export enum Color {
  LIGHT_BLUE = "lightblue",
  BLUE = "blue",
  DARK_BLUE = "darkblue",
  LIGHT_PINK = "lightpink",
  PINK = "pink",
  RED = "red",
  DARK_RED = "darkred",
  BROWN = "brown",
  YELLOW = "yellow",
  MINT_GREEN = "mintgreen",
  LIGHT_GREEN = "lightgreen",
  GREEN = "green",
  DARK_GREEN = "darkgreen",
  GRAY = "gray",
  PURPLE = "purple",
}

export const productionUnitColors: ProductionUnitColors = {
  326: Color.DARK_BLUE, // Bellahøjvej
  327: Color.GRAY, // Borups Allé
  328: Color.DARK_RED, // Domus Vista
  329: Color.RED, // Finsensvej
  330: Color.YELLOW, // Hellerup
  331: Color.PURPLE, // Christianshavn
  332: Color.PINK, // Herlev
  333: Color.DARK_GREEN, // Matthæusgade
  334: Color.BLUE, // Tårnby
  335: Color.BROWN, // Valby
  336: Color.LIGHT_GREEN, // Bodenhoffs Bageri
  365: Color.MINT_GREEN, // Toftegaardsplads
};

export const productColors: ProductColors = {
  6157: Color.DARK_BLUE, // Mini wienerbrød
  6222: Color.BROWN, // Wiener kringle
  6225: Color.RED, // Små snegle
  6228: Color.DARK_BLUE, // Mini winerbrødskagemanden
  6229: Color.GREEN, // Glutenfri
  6230: Color.PINK, // Hindbærsnitter
  6231: Color.PINK, // Kernespeltbolle
  6232: Color.YELLOW, // Rosinboller
  6233: Color.YELLOW, // Tebolle
  6234: Color.DARK_BLUE, // Wienerbrød
  6235: Color.LIGHT_BLUE, // Mini brunsviger
  6237: Color.LIGHT_BLUE, // Brunsviger
  6238: Color.GRAY, // Kanelstang
  6239: Color.PINK, // Romkuglemand
  6240: Color.LIGHT_PINK, // Tvilling kagemand hindbærsnitte
  6241: Color.GRAY, // Kanelstangskringle
  6242: Color.LIGHT_BLUE, // Plade brunsviger
  6243: Color.PINK, // Plade hindbærsnitter
  6631: Color.DARK_BLUE, // Plade wienerbrød
  6864: Color.DARK_BLUE, // Wienerbrødskringle
  7935: Color.DARK_BLUE, // Tvilling wienerbrød kagemand
  7940: Color.LIGHT_BLUE, // Tvilling brunsviger kagemand
};

export const choiceColors: ChoiceColors = {
  // Køn
  976: {
    3547: Color.LIGHT_BLUE, // Kagemand
    3548: Color.LIGHT_PINK, // Kagekone
    3786: Color.YELLOW, // Tvilling
  },

  // Hår
  989: {
    3600: Color.GRAY, // Lakrids
    3601: Color.BROWN, // Karamel
    3602: Color.PINK, // Jordbær
  },

  // Slik
  990: {
    3604: Color.LIGHT_BLUE, // Alm
    3606: Color.GREEN, // Vegansk
    3625: Color.YELLOW, // Smarties
  },

  // Flag
  991: {
    3607: Color.BLUE, // 1 pakke
    3613: Color.GRAY, // 1 pakke
  },
};
