import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import chevronSrc from "assets/img/icons/chevron.svg";
import Modal from "components/modal/Modal";
import OrderDetails from "components/order/OrderDetails";
import ProductionUnitsTable from "components/table/productionUnits/ProductionUnitsTable";
import ProductTable from "components/table/product/ProductTable";
import { IOrderDetails, TimeLimit } from "models/table";
import { useOrderSelector } from "store/order";
import { List } from "utils/constants/list";

import "./List.scss";

export default function ListView() {
  const { chosenDate } = useOrderSelector();
  const { name: paramName } = useParams();
  const { t } = useTranslation();

  const productionUnitsTableLists = [List.KAGER, List.BRØD, List.WIENERBRØD, List.INTERNE_ORDRER] as string[];

  const name = useMemo(() => (paramName ? decodeURI(paramName) : ""), [paramName]);

  const [orderToInspect, setOrderToInspect] = useState<IOrderDetails | null>(null);

  function handleInspectOrder(orderDetails: IOrderDetails) {
    setOrderToInspect(orderDetails);
  }

  function handleDismissModal() {
    setOrderToInspect(null);
  }

  function TableContent(list: string) {
    if (list === List.KAGER) {
      return (
        <>
          <section>
            <h2>{t("table:ProductionUnitsTable.Title.Total")}</h2>
            <ProductionUnitsTable list={name} />
          </section>

          <section>
            <h2>{t("table:ProductionUnitsTable.Title.BeforeNoon")}</h2>
            <ProductionUnitsTable list={name} timeLimit={TimeLimit.BEFORE_NOON} />
          </section>
        </>
      );
    }

    if (productionUnitsTableLists.includes(list.toLowerCase())) {
      return <ProductionUnitsTable list={name} />;
    }

    return <ProductTable list={list} handleInspectOrder={handleInspectOrder} />;
  }

  return (
    <>
      <nav>
        <Link id="go-back-link" to={{ pathname: "/" }}>
          <div style={{ backgroundImage: `url(${chevronSrc})` }}></div>
          <span>{t("common:GoBack")}</span>
        </Link>
      </nav>

      <div className={`tables ${orderToInspect ? "under-modal" : ""}`}>
        <div className="header">
          <h1>{name}</h1>
          <p className="date">{moment(chosenDate).format("DD/MM/YYYY")}</p>
        </div>

        {TableContent(name)}
      </div>

      {orderToInspect && <Modal handleDismiss={handleDismissModal} content={<OrderDetails orderDetails={orderToInspect} handleDismiss={handleDismissModal} />} />}
    </>
  );
}
