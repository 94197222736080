import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDatepicker from "./CustomDatepicker";
import { useOrderSelector } from "store/order";
import { getToday } from "utils/helpers/date";
import "./Datepicker.scss";

interface IDatepickerProps {
  handleDateChange: (date: moment.Moment) => void;
}

enum Datepicker {
  TODAY,
  TOMORROW,
  CUSTOM,
}

export default function Datepickers(props: IDatepickerProps) {
  const { handleDateChange } = props;
  const { chosenDate, isLoading } = useOrderSelector();
  const { t } = useTranslation();

  const [activeDatepicker, setActiveDatepicker] = useState<Datepicker | null>(null);
  const [customDate, setCustomDate] = useState<moment.Moment | null>(null);

  const today = useMemo(() => getToday(), []);
  const tomorrow = useMemo(() => moment(today).date(today.date() + 1), [today]);

  const getIsActive = useCallback(
    (datepicker: Datepicker) => {
      return activeDatepicker === datepicker;
    },
    [activeDatepicker]
  );

  function resetCustomDate() {
    setCustomDate(null);
  }

  function handleChooseToday() {
    resetCustomDate();
    handleDateChange(today);
  }

  function handleChooseTomorrow() {
    resetCustomDate();
    handleDateChange(tomorrow);
  }

  function handleChooseCustomDate(date: moment.Moment) {
    setCustomDate(date);
    handleDateChange(date);
  }

  // Sync with store
  useEffect(() => {
    if (!chosenDate || isLoading) {
      return;
    }

    if (moment(chosenDate).isSame(today, "day")) {
      setActiveDatepicker(Datepicker.TODAY);
      return;
    }

    if (moment(chosenDate).isSame(tomorrow, "day")) {
      setActiveDatepicker(Datepicker.TOMORROW);
      return;
    }

    setCustomDate(moment(chosenDate));
    setActiveDatepicker(Datepicker.CUSTOM);
  }, [chosenDate, isLoading, today, tomorrow, activeDatepicker]);

  return (
    <div className="datepicker">
      <button className={`like-button ${getIsActive(Datepicker.TODAY) ? "active" : ""}`} onClick={handleChooseToday} disabled={getIsActive(Datepicker.TODAY)}>
        {t("date:Today")}
      </button>

      <button className={`like-button ${getIsActive(Datepicker.TOMORROW) ? "active" : ""}`} onClick={handleChooseTomorrow} disabled={getIsActive(Datepicker.TOMORROW)}>
        {t("date:Tomorrow")}
      </button>

      <CustomDatepicker chosenCustomDate={customDate} isActive={getIsActive(Datepicker.CUSTOM)} handleDateChange={handleChooseCustomDate} />
    </div>
  );
}
