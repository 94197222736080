import moment from "moment";
import { useCallback } from "react";
import Datepicker from "components/date/Datepicker";
import ListItem from "components/list/ListItem";
import useAppState from "hooks/useAppState";
import useOrderProducts from "hooks/useOrderProducts";
import { orderActions } from "store/order";
import { useCustomDispatch } from "store/useStore";
import { List, lists } from "utils/constants/list";
import { formatDate } from "utils/helpers/date";

import "./Home.scss";

export default function Home() {
  const { isLoading } = useAppState();
  const { getOrderProducts } = useOrderProducts();

  const dispatch = useCustomDispatch();

  const getOrderProductCount = useCallback((key: string) => (isLoading ? null : getOrderProducts(key).length), [isLoading, getOrderProducts]);

  const rightColLists = [List.BRØD, List.WIENERBRØD, List.KAGEMÆND, List.INTERNE_ORDRER];
  const leftColLists = lists.filter((list) => !rightColLists.includes(list));

  function handleDateChange(newDate: moment.Moment) {
    const chosenDate = formatDate(newDate);
    dispatch(orderActions.setChosenDateUtc(chosenDate));
  }

  return (
    <>
      <Datepicker handleDateChange={handleDateChange} />

      <div className="lists">
        <ul className="left-col">
          {leftColLists.map((key) => {
            return <ListItem listKey={key} key={key} orderProductCount={getOrderProductCount(key)} />;
          })}
        </ul>

        <ul className="right-col">
          {rightColLists.map((key) => {
            return <ListItem listKey={key} key={key} orderProductCount={getOrderProductCount(key)} />;
          })}
        </ul>
      </div>
    </>
  );
}
