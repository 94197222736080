import { useTranslation } from "react-i18next";

export default function PrintButton() {
  const { t } = useTranslation();

  function handlePrintClick() {
    window.print();
  }

  return (
    <button className="button print-button" onClick={handlePrintClick}>
      {t("table:Print")}
    </button>
  );
}
