import { ModuleType } from "@crunchit/types";
import moment from "moment";
import { useCallback } from "react";

import { IOrderDto } from "models/order";
import { TimeLimit } from "models/table";
import { useAppSelector } from "store/app";
import { useOrderSelector } from "store/order";

export default function useOrderProducts() {
  const { modules } = useAppSelector();
  const { orders, chosenDate } = useOrderSelector();

  const getAllOrderProducts = (orders: IOrderDto[]) => {
    return orders.flatMap((order) => {
      return order.products.map((orderProduct) => {
        return {
          ...orderProduct,
          orderId: order.id,
          orderReadyTime: order.orderReadyTime,
          moduleId: order.moduleId,
          productionUnitId: order.productionUnitId,
        };
      });
    });
  };

  const getOrderProducts = useCallback(
    (listName: string, timeLimit?: TimeLimit) => {
      // Filter on module
      const modulesOfType = modules.filter((module) => module.type === ModuleType.FASTTRACK || module.type === ModuleType.TAKEAWAY);
      let filteredOrders = orders.filter((order) => modulesOfType.find((module) => module.id === order.moduleId) !== undefined);

      // Filter on time limit
      if (timeLimit === TimeLimit.BEFORE_NOON) {
        const beforeNoon = moment(chosenDate).hours(11).minutes(55);
        filteredOrders = filteredOrders.filter(({ orderReadyTime }) => moment(orderReadyTime).isBefore(beforeNoon));
      } else if (timeLimit === TimeLimit.AFTER_NOON) {
        const afterNoon = moment(chosenDate).hours(11).minutes(59).seconds(59);
        filteredOrders = filteredOrders.filter(({ orderReadyTime }) => moment(orderReadyTime).isAfter(afterNoon));
      }

      // Get all products from the filtered orders
      const allOrderProducts = getAllOrderProducts(filteredOrders);

      // Finally, filter the products by group tag
      return allOrderProducts.filter((product) => product.groupTag && product.groupTag.name.toLowerCase() === listName.toLowerCase());
    },
    [orders, modules, chosenDate]
  );

  return {
    getOrderProducts,
  };
}
