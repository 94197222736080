import { ICriticalError } from "@crunchit/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IOrderDto } from "models/order";
import moment from "moment";
import OrderService from "services/OrderService";
import ProductionUnitService from "services/ProductionUnitService";
import { RootState } from "store/store";
import { formatDate } from "utils/helpers/date";

type Rejection = { rejectValue: ICriticalError };
type LoadOrdersSuccess = { orders: IOrderDto[] };

const filterOrderedAfter = (date: moment.Moment, order: IOrderDto) => moment(order.orderTimeUtc).isBefore(date);
const sortByReadyTime = (orderA: IOrderDto, orderB: IOrderDto) => new Date(orderB.orderReadyTime).getTime() - new Date(orderA.orderReadyTime).getTime();

function getLatestOrderingDateUtc(localNow: moment.Moment, chosenDate: moment.Moment) {
  const chosenDateUtc = chosenDate.utc(); // Will be the previous day at 22:00 or 23:00, since chosen date is at 00:00 local Danish time

  const localHours = localNow.hours();
  const localMinutes = localNow.minutes();

  const utcNow = moment.utc();
  utcNow.subtract(localHours, "hours");
  utcNow.subtract(localMinutes, "minutes");

  utcNow.date(chosenDateUtc.date());
  utcNow.month(chosenDate.month());
  utcNow.seconds(0);

  return utcNow;
}

export const loadOrders = createAsyncThunk<LoadOrdersSuccess, void, Rejection>("order/loadOrders", async (data, { getState, rejectWithValue }) => {
  const state = getState() as RootState;

  try {
    const chosenDate = moment(state.order.chosenDate);
    const date = formatDate(chosenDate);

    const ordersResponse = await OrderService.getOrders({ date });

    if (!ordersResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to fetch orders'`, error: ordersResponse.errors });
    }

    if (!ordersResponse.data.length) {
      return { orders: [] };
    }

    const productionUnitId = ordersResponse.data.at(0)!!.productionUnitId;
    const productionUnitNowResponse = await ProductionUnitService.getNow(productionUnitId);

    if (!productionUnitNowResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to fetch orders'`, error: productionUnitNowResponse.errors });
    }

    const localNow = moment(productionUnitNowResponse.data);
    const latestOrderDateUtc = getLatestOrderingDateUtc(localNow, chosenDate);

    const orders = ordersResponse.data.filter((order) => filterOrderedAfter(latestOrderDateUtc, order)).sort(sortByReadyTime);

    return { orders };
  } catch (error) {
    return rejectWithValue({ message: "Unable to initialize app", error: error instanceof Error ? error.message : error });
  }
});

const thunks = {
  loadOrders,
};

export default thunks;
