import { IProductRow } from "models/table";
import { productionUnitColors } from "utils/constants/colors";

export default function LocationTableCell({ location }: IProductRow) {
  if (location) {
    const className = `location bg-${productionUnitColors[location.id] || "none"}`;
    return <span className={className}>{location.name}</span>;
  }

  return "";
}
