import { useCallback, useEffect, useState } from "react";
import useTableFiltering from "./useTableFiltering";
import useTableSorting from "./useTableSorting";
import { IOrderDto, IOrderProductDto } from "models/order";
import { IProductRow, SortingFunction } from "models/table";
import { useAppSelector } from "store/app";
import { useOrderSelector } from "store/order";
import { productSortingFunctions } from "utils/constants/table";

/**
 * Prepares, filters and sorts order product rows.
 */
export default function useProductTable() {
  const { productionUnits } = useAppSelector();
  const { orders } = useOrderSelector();

  const { setSortingFunction, sort, applyCurrentSorting, sortIcon } = useTableSorting<IProductRow>();
  const { filterByDate, filterByReceiptId } = useTableFiltering();

  const [rows, setRows] = useState<readonly IProductRow[]>([]); // Original rows – kept while filtering
  const [viewRows, setViewRows] = useState<IProductRow[]>([]); // The currently viewed rows

  const handleDateFiltering = useCallback(
    (fromDate: Date, toDate: Date) => {
      const filteredRows = filterByDate(rows, fromDate, toDate);
      const filteredAndSortedRows = applyCurrentSorting(filteredRows);

      setViewRows([...filteredAndSortedRows]);
    },
    [rows, filterByDate, applyCurrentSorting]
  );

  const handleSearch = useCallback(
    (externalId: string) => {
      const filteredRows = filterByReceiptId(rows, externalId);
      const filteredAndSortedRows = applyCurrentSorting(filteredRows);

      setViewRows([...filteredAndSortedRows]);
    },
    [rows, filterByReceiptId, applyCurrentSorting]
  );

  const handleResetFiltering = useCallback(() => {
    const originalRows = [...rows];
    const sortedOriginalRows = applyCurrentSorting(originalRows);

    setViewRows(sortedOriginalRows);
  }, [rows, applyCurrentSorting]);

  const handleSorting = useCallback(
    (sortingFunction: SortingFunction<IProductRow>) => {
      const sortedRows = sort(viewRows, sortingFunction);
      setViewRows([...sortedRows]);
    },
    [sort, viewRows]
  );

  const orderProductMapper = (orderProduct: IOrderProductDto): IProductRow => {
    const order = orders.find((order) => order.id === orderProduct.orderId) as IOrderDto;

    const orderReadyTime = new Date(order.orderReadyTime);
    orderReadyTime.setSeconds(0);
    orderReadyTime.setMilliseconds(0);

    const location = productionUnits.find((p) => p.id === order.productionUnitId);

    return {
      ...orderProduct,
      receiptId: order.receipt.number,
      externalId: order.externalId || "",
      productName: orderProduct.name,
      comment: orderProduct.guestComment,
      orderReadyTime,
      order,
      location,
    };
  };

  /**
   * Readies rows to be viewed in the table.
   */
  const loadRows = (orderProducts: IOrderProductDto[]) => {
    let rows = orderProducts.map(orderProductMapper);

    const defaultSortingFunction = productSortingFunctions.sortByName;
    rows = rows.sort(defaultSortingFunction);

    setRows(rows);
    setSortingFunction(defaultSortingFunction);
  };

  useEffect(() => {
    setViewRows([...rows]);
  }, [rows]);

  return {
    loadRows,
    rows: viewRows,
    handleSorting,
    handleDateFiltering,
    handleSearch,
    handleResetFiltering,
    sortIcon,
  };
}
