import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "components/ui/forms";
import useAuth from "hooks/useAuth";
import { appActions } from "store/app";
import { useCustomDispatch } from "store/useStore";

import "./Login.scss";

export default function Login() {
  const { t } = useTranslation();
  const { saveToken } = useAuth();

  const dispatch = useCustomDispatch();

  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  async function onSubmit(event: FormEvent) {
    event.preventDefault();

    if (!password.length) {
      setShowError(true);
      return;
    }

    saveToken(password);
    dispatch(appActions.setToken(password));
  }

  return (
    <form onSubmit={onSubmit} className={showError ? "show-validation-errors" : ""}>
      <Input inputValue={password} overrideParameters={{ name: "password", type: "password", placeholder: t("login:Password") }} label={t("login:Password")} errorMessage={t("login:Errors.MissingField")} inputChanged={({ value }) => setPassword(value)} />
      <button className="button" type="submit">
        {t("login:LogIn")}
      </button>
    </form>
  );
}
