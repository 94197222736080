import { ComponentType, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";
import { IViewProps } from "models/layout";
import { appActions, appThunks, useAppSelector } from "store/app";
import { orderActions, orderThunks, useOrderSelector } from "store/order";
import { useCustomDispatch } from "store/useStore";
import { formatDate, getToday } from "utils/helpers/date";
import { chosenDateName } from "utils/constants/storage";

// HOC for custom data load in all page views
export default function withInitializedData(WrappedComponent: ComponentType<IViewProps>) {
  return function (props: IViewProps) {
    const [ordersLoadIsInitialized, setOrdersLoadIsInitialized] = useState(false);
    const [modifiersAreLoaded, setModifiersAreLoaded] = useState(false);

    const { appIsAuthenticated, appIsInitialized, modules, isLoading: appIsLoading, error: appError } = useAppSelector();
    const { chosenDate } = useOrderSelector();
    const { getToken } = useAuth();

    const dispatch = useCustomDispatch();
    const redirect = useNavigate();

    // Initialize
    useEffect(() => {
      if (appIsAuthenticated && !appIsInitialized && !appIsLoading && !appError) {
        dispatch(appThunks.initialize());
      }
    }, [appIsAuthenticated, appIsInitialized, appIsLoading, appError, dispatch]);

    // Load modifiers
    useEffect(() => {
      if (modules.length && !modifiersAreLoaded && !appIsLoading && !appError) {
        dispatch(appThunks.loadModifiers(modules));
        setModifiersAreLoaded(true);
      }
    }, [modules, modifiersAreLoaded, appIsLoading, appError, dispatch]);

    // Load orders
    useEffect(() => {
      if (appIsAuthenticated && !ordersLoadIsInitialized && chosenDate) {
        setOrdersLoadIsInitialized(true);
        dispatch(orderThunks.loadOrders());
        setOrdersLoadIsInitialized(false);
      }
    }, [appIsAuthenticated, ordersLoadIsInitialized, chosenDate, dispatch]);

    // Set chosen date
    useEffect(() => {
      if (appIsAuthenticated && !chosenDate) {
        const sessionChosenDate = sessionStorage.getItem(chosenDateName);

        if (sessionChosenDate) {
          dispatch(orderActions.setChosenDateUtc(sessionChosenDate));
          return;
        }

        const todayUtc = formatDate(getToday());
        dispatch(orderActions.setChosenDateUtc(todayUtc));
      }
    }, [appIsAuthenticated, chosenDate, dispatch]);

    // Authenticate
    useEffect(() => {
      if (appIsAuthenticated) {
        return;
      }

      const token = getToken();

      if (token) {
        dispatch(appActions.setToken(token));
      }

      dispatch(appActions.setIsLoading(false));
    }, [appIsAuthenticated, getToken, redirect, dispatch]);

    // Reset initialization when unauthenticated
    useEffect(() => {
      if (!appIsAuthenticated) {
        if (ordersLoadIsInitialized) {
          setOrdersLoadIsInitialized(false);
        }

        if (modifiersAreLoaded) {
          setModifiersAreLoaded(false);
        }
      }
    }, [appIsAuthenticated, ordersLoadIsInitialized, modifiersAreLoaded]);

    return <WrappedComponent {...props} />;
  };
}
