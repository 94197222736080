export enum List {
  KAGER = "kager",
  LAGKAGER = "lagkager",
  SPECIALKAGER = "specialkager",
  SPECIALKAGEMÆND = "specialkagemænd",
  BRØD = "brød",
  WIENERBRØD = "wienerbrød",
  KAGEMÆND = "kagemænd",
  INTERNE_ORDRER = "interne ordrer",
}

export const lists = [List.KAGER, List.LAGKAGER, List.SPECIALKAGER, List.SPECIALKAGEMÆND, List.BRØD, List.WIENERBRØD, List.KAGEMÆND, List.INTERNE_ORDRER];
