import { siteStorageName } from "@crunchit/types";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "@crunchit/utilities";
import { IAuth } from "models/auth";
import { appActions } from "store/app";
import { orderActions } from "store/order";
import { useCustomDispatch } from "store/useStore";
import { authName } from "utils/constants/storage";

export default function useAuth() {
  const expirationInMs = 2592000000; // 30 days

  const dispatch = useCustomDispatch();

  function getToken() {
    const authValue = getFromLocalStorage(siteStorageName, authName);
    let token = null;

    if (authValue) {
      try {
        const auth = JSON.parse(authValue) as IAuth;
        const expired = auth.timestamp + expirationInMs < Date.now();

        if (expired) {
          throw new Error();
        }

        token = auth.token;
      } catch (error) {
        removeFromLocalStorage(siteStorageName, authName);
      }
    }

    return token;
  }

  function saveToken(token: string) {
    const auth: IAuth = {
      token,
      timestamp: Date.now(),
    };

    saveToLocalStorage(siteStorageName, authName, JSON.stringify(auth));
  }

  function signOut() {
    removeFromLocalStorage(siteStorageName, authName);
    
    dispatch(orderActions.setChosenDateUtc(""));
    dispatch(appActions.reset());
  }

  return {
    getToken,
    saveToken,
    signOut,
  };
}
