import { IOrderQueryParams } from "models/order";
import { store } from "store/store";

let environment = "";

if (window.location.href.includes("-ci.azurewebsites.net")) {
  environment = "ci.";
} else if (window.location.href.includes("-staging.azurewebsites.net")) {
  environment = "staging.";
}

const getToken = () => {
  return store.getState().app.token;
};

const getEnvironment = () => {
  return environment;
};

const getOrderParamString = (params: IOrderQueryParams) => {
  let paramObj = new URLSearchParams();

  if (params.date) {
    paramObj.append("date", params.date);
  }

  if (params.moduleId) {
    paramObj.append("moduleId", params.moduleId);
  }

  const paramsObjString = paramObj.toString();

  return paramsObjString.length ? `?${paramsObjString}` : "";
};

export { getToken, getEnvironment, getOrderParamString };
