import { IOrderItemChoiceDto, IProductionUnitDto } from "@crunchit/types";
import { IOrderDto, IOrderProductDto } from "./order";

export type ProductAmountMap = Map<number, Map<number, number>>; // Production unit id -> product id -> product amount

export interface IProduct {
  productId: number;
  name: string;
}

export interface IProductRow {
  productName: string;
  productId: number;
  amount: number;
  orderReadyTime: Date;
  receiptId: number;
  externalId: string;
  order: IOrderDto;
  location?: IProductionUnitDto;
  choices?: IOrderItemChoiceDto[];
  comment?: string;
}

export interface IOrderProductRow extends IOrderProductDto {
  totalAmount: number;
  amountPerProductionUnit: ProductAmountMap | null;
}

export type SortingFunction<T> = (rowA: T, rowB: T) => number;

export interface IOrderDetails {
  order: IOrderDto;
  product: IProductRow;
}

type ProductColumnContent = (product: IProductRow) => string | JSX.Element;

// For showing product data in column
export class ProductColumn {
  translationKey: string;
  content: ProductColumnContent;
  sorting?: SortingFunction<IProductRow>;
  className?: string;

  constructor({ translationKey, content, sorting, className }: { translationKey: string; content: ProductColumnContent; sorting?: SortingFunction<IProductRow>; className?: string }) {
    this.translationKey = translationKey;
    this.content = content;
    this.sorting = sorting;
    this.className = className;
  }

  isModifierColumn(): this is ModifierColumn {
    return false;
  }
}

// For showing modifier data in column
export class ModifierColumn {
  modifierId: number;
  className: string;
  translationKey?: string;

  /**
   *
   * @param modifierId
   * @param translationKey For overwriting the column header text
   */
  constructor(modifierId: number, className?: string, translationKey?: string) {
    this.modifierId = modifierId;
    this.className = className || "";
    this.translationKey = translationKey;
  }

  isModifierColumn(): this is ModifierColumn {
    return true;
  }
}

export type Column = ProductColumn | ModifierColumn;

export enum TimeLimit {
  BEFORE_NOON,
  AFTER_NOON,
}
