import { useAppSelector } from "store/app";
import { useOrderSelector } from "store/order";
import { browserIsOutdated } from "utils/helpers/errors";

export default function useAppState() {
  const app = useAppSelector();
  const order = useOrderSelector();

  const error = app.error || order.error;
  const isError = !!error || browserIsOutdated();

  const isLoading = app.isLoading || order.isLoading;

  return { isError, error, isLoading };
}
