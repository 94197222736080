import { useMemo } from "react";
import { SortingFunction } from "models/table";
import "./SortableTableHeader.scss";

interface ISortableTableHeaderProps<T> {
  text: string;
  sortingFunction: SortingFunction<T>;
  getIcon: (f: SortingFunction<T>) => string;
  handleClick: (f: SortingFunction<T>) => void;
  classNames?: string;
}

export default function SortableTableHeader<T>(props: ISortableTableHeaderProps<T>) {
  const { text, classNames, sortingFunction, getIcon, handleClick } = props;

  const icon = useMemo(() => getIcon(sortingFunction), [getIcon, sortingFunction]);

  return (
    <th className={`sortable ${classNames || ""}`} onClick={() => handleClick(sortingFunction)}>
      <div>
        {text}
        <i>{icon}</i>
      </div>
    </th>
  );
}
