import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductionUnitTableHeader from "./header/ProductionUnitTableHeader";
import OrderProductTableRow from "./row/OrderProductTableRow";
import useAppState from "hooks/useAppState";
import useProductionUnitsTable from "./useProductionUnitsTable";
import useOrderProducts from "hooks/useOrderProducts";
import { TimeLimit } from "models/table";
import { useAppSelector } from "store/app";
import { useOrderSelector } from "store/order";

import "./ProductionUnitsTable.scss";
import SortableTableHeader from "../product/header/SortableTableHeader";
import { orderProductSortingFunctions } from "utils/constants/table";

interface IProductionUnitsTableProps {
  list: string;
  timeLimit?: TimeLimit;
}

/**
 * Production units overview.
 *
 * Shows how many of each product have been ordered from each production unit.
 */
export default function ProductionUnitsTable({ list, timeLimit }: IProductionUnitsTableProps) {
  const { appIsInitialized, productionUnits } = useAppSelector();
  const { isLoading } = useAppState();
  const { orders } = useOrderSelector();
  const { t } = useTranslation();
  const { getOrderProducts } = useOrderProducts();

  const { loadRows, rows, sortIcon, handleSorting } = useProductionUnitsTable();

  const [rowsLoaded, setRowsLoaded] = useState(false);

  /**
   * Initial filtering and load of orders
   */
  useEffect(() => {
    if (appIsInitialized && !isLoading && !rowsLoaded) {
      const orderProducts = getOrderProducts(list, timeLimit);

      loadRows(orderProducts);
      setRowsLoaded(true);
    }
  }, [appIsInitialized, isLoading, rowsLoaded, getOrderProducts, list, timeLimit, loadRows]);

  useEffect(() => {
    setRowsLoaded(false);
  }, [orders]);

  return (
    <div className="table-wrapper">
      <table className="production-units-table">
        <thead>
          <tr>
            <SortableTableHeader text={t("table:ProductionUnitsTable.Product")} classNames="product" sortingFunction={orderProductSortingFunctions.sortByName} handleClick={handleSorting} getIcon={sortIcon} />
            <SortableTableHeader text={t("table:ProductionUnitsTable.Total")} classNames="total" sortingFunction={orderProductSortingFunctions.sortByTotalAmount} handleClick={handleSorting} getIcon={sortIcon} />

            {productionUnits.map((productionUnit) => (
              <ProductionUnitTableHeader productionUnit={productionUnit} key={productionUnit.id} />
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.length ? (
            rows.map((orderProduct) => <OrderProductTableRow orderProduct={orderProduct} key={orderProduct.productId} />)
          ) : (
            <tr>
              <td colSpan={2 + productionUnits.length}>{t("table:Empty")}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
